import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'
import { differenceInMinutes } from 'date-fns'
import { motion } from 'framer-motion'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from '../configuration'
import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import * as icons from '../icons'
import * as ServerConfig from '../server-configuration'
import { Balance } from '../balance'
import { Button } from '../button'
import { Divider } from '../divider'
import { Logotype } from '../logotype'
import { MenuItem } from '../menu-item'
import { MenuNotificationBadge } from '../menu-notification-badge'
import { PromotionsMenuItem } from '../promotions-menu-item'
import { ScrollLock } from '../scroll-lock'
import { StoreMenuItem } from '../store-menu-item'
import { SupportChatMenuItem } from '../support-chat-menu-item'
import { WalletMenuItem } from '../wallet-menu-item'
import { useAuthentication } from '../combined-selectors'
import { useMenuQueries } from '../use-menu-queries'
import { usePendingTransactions } from '../pending-transactions'

const Wrapper = styled.header`
  ${css({
    backgroundColor: 'nav',
    position: 'sticky',
    top: '0px',
    left: '0px',
    height: [null, null, '100vh'],
    width: ['100%', null, '200px'],
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: [110, 90],
  })};

  flex-shrink: 0;

  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${t.color('nav')};
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

const Menu = styled(motion.div)`
  ${props =>
    css({
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: [null, null, 'space-between'],
      height: ['calc(var(--window-inner-height, 100vh) - 46px)', null, 'auto'],
      overflowY: ['auto', null, 'initial'],
      backgroundImage: [`url(${props.background})`, null, null, 'none'],
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    })};

  -webkit-overflow-scrolling: touch;

  // These styles are only to override the animation styles from small screen
  ${t.mediaQuery.md`
    display: inline-flex !important;
    transform: translate(0) !important;
    opacity: 1 !important;
  `}

  ${t.mediaQuery.sm`
    background-image: none!important;
  `}

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${t.color('nav')};
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${t.color('g-text', 0.5)};
      }
    }
  }
`

const variants = {
  open: {
    y: '0%',
    opacity: 1,
    transition: {
      x: { type: 'spring', stiffness: 200, damping: 25 },
      default: {
        duration: 0.2,
      },
    },
    display: 'inline-flex',
  },
  closed: {
    y: '-100%',
    opacity: 0,
    transition: {
      duration: 0.2,
    },
    transitionEnd: {
      display: 'none',
    },
  },
}

export function MainMenu(props) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [returningPlayer] = Cookies.useCookie('returning_player')
  const i18n = I18n.useI18n()
  const location = ReactRouter.useLocation()
  const { country } = ServerConfig.useContext()
  const {
    briteLoginDepositQuery,
    depositQuery,
    withdrawalQuery,
    loginQuery,
    registerQuery,
  } = useMenuQueries()
  const authenticated = useAuthentication()

  const manualSignupEnabled = ReactRedux.useSelector(state =>
    Jurisdiction.getManualSignUpAllowed(state.jurisdiction)
  )
  const briteSigninEnabled = ReactRedux.useSelector(state =>
    Configuration.getBriteSigninEnabled(state.configuration)
  )
  const expertBlogEnabled = ReactRedux.useSelector(state =>
    Configuration.expertBlogEnabled(state.configuration)
  )

  const { pendingTransactions } = usePendingTransactions()

  React.useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  return (
    <Wrapper data-testid="main-menu">
      {isMenuOpen && !Constants.isDesktop && <ScrollLock />}
      {/* Topbar */}
      <Common.Box
        color="g-text"
        py={[0, null, 1]}
        pl={[0, null, '0px']}
        pr={[0, null, 1]}
        backgroundColor="nav"
        position="sticky"
        top="0px"
        left="0px"
        zIndex="99999999"
        display="flex"
        justifyContent={['space-between', null, 'stretch']}
        alignItems={['center', null, 'stretch']}
        flexDirection={['row', null, 'column']}
        flexShrink="0"
      >
        <Common.Box display="flex" justifyContent="center" pl={[null, null, 1]}>
          <ReactRouter.Link to="/" data-testid="main-menu-logotype">
            <Logotype title="Wettenlive" />
          </ReactRouter.Link>
        </Common.Box>
        <Common.Flex>
          <Common.Box
            pt={[null, null, 2]}
            flexGrow="1"
            flexDirection={['row-reverse', null, 'column']}
            display="flex"
          >
            {props.authenticated ? (
              <ReactRouter.Link
                to={`?${depositQuery}`}
                data-testid="main-menu-balance"
              >
                <Balance country={country} />
              </ReactRouter.Link>
            ) : !country.enabled ? (
              <Common.Space pl={[0, null, 1]}>
                <ReactRouter.Link
                  to={`?${loginQuery}`}
                  data-testid="main-menu-login"
                >
                  <Button stretch variant="secondary" fontSize={[1, null, 3]}>
                    {i18n.translate('main-menu.login')}
                  </Button>
                </ReactRouter.Link>
              </Common.Space>
            ) : (
              <Common.Box
                display="flex"
                flexDirection={['row-reverse', null, 'column']}
              >
                {returningPlayer && (
                  <Common.Space pl={[0, null, 1]}>
                    <ReactRouter.Link
                      to={`?${loginQuery}`}
                      data-testid="main-menu-login"
                    >
                      <Button
                        stretch
                        variant="secondary"
                        fontSize={[1, null, 3]}
                      >
                        {i18n.translate('main-menu.login')}
                      </Button>
                    </ReactRouter.Link>
                  </Common.Space>
                )}
                {!returningPlayer && (
                  <Common.Space pt={[null, null, 1]} pl={[null, null, 1]}>
                    <ReactRouter.Link
                      to={
                        briteSigninEnabled
                          ? `?${briteLoginDepositQuery}`
                          : `?${registerQuery}`
                      }
                      data-testid={
                        briteSigninEnabled
                          ? 'main-menu-pay-and-play-register'
                          : 'main-menu-manual-register'
                      }
                    >
                      <Button
                        stretch
                        variant="secondary"
                        fontSize={[1, null, 3]}
                      >
                        {i18n.translate('main-menu.register')}
                      </Button>
                    </ReactRouter.Link>
                  </Common.Space>
                )}
              </Common.Box>
            )}
          </Common.Box>
          <Common.Box
            data-testid="main-menu-menu-toggler"
            display={['inline-flex', null, 'none']}
            alignItems="center"
            pl={0}
            fontSize="29px"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <icons.Clear />
            ) : (
              <MenuNotificationBadge>
                <icons.Menu />
              </MenuNotificationBadge>
            )}
          </Common.Box>
        </Common.Flex>
      </Common.Box>

      {/* Navigations */}
      <Menu
        background={i18n.translate('mobile-menu-background')}
        variants={variants}
        animate={isMenuOpen ? 'open' : 'closed'}
        initial="closed"
      >
        <nav>
          {R.not(R.isEmpty(pendingTransactions)) && (
            <WalletMenuItem
              disabled={props.locked || !props.authenticated}
              highlightColor="secondary"
              icon={icons.CoinStack}
              testId="main-menu-wallet-pending-withdrawals"
              to={`?${withdrawalQuery}`}
            >
              {i18n.translate('main-menu.pending-withdrawals')}
            </WalletMenuItem>
          )}
          <WalletMenuItem
            animate={props.hasLowBalance}
            disabled={props.locked || !props.authenticated}
            highlightColor="primary"
            icon={icons.BankNote}
            testId="main-menu-wallet-deposit"
            to={`?${depositQuery}`}
          >
            {i18n.translate('main-menu.deposit')}
          </WalletMenuItem>
          <Common.Space py={0} px={1}>
            <Divider />
          </Common.Space>
          <MenuItem
            disabled={props.locked}
            icon={icons.ViewModule}
            testId="main-menu-casino"
            to="/casino"
          >
            {i18n.translate('main-menu.casino')}
          </MenuItem>
          <MenuItem
            disabled={props.locked}
            icon={icons.Videocam}
            testId="main-menu-live-casino"
            to="/live-casino"
          >
            {i18n.translate('main-menu.live-casino')}
          </MenuItem>
          {country.alpha2 !== 'DK' &&
            country.alpha2 !== 'FR' &&
            country.alpha2 !== 'NL' &&
            country.alpha2 !== 'SE' && (
              <MenuItem
                disabled={props.locked || !props.authenticated}
                icon={icons.Videocam}
                to="/pragmatic-live"
              >
                {i18n.translate('main-menu.pragmatic-live')}
              </MenuItem>
            )}
          <MenuItem
            disabled={props.locked}
            testId="main-menu-sports"
            icon={icons.Ball}
            to="/sports"
          >
            {i18n.translate('main-menu.sport')}
          </MenuItem>
          {props.storeEnabled && (
            <StoreMenuItem
              disabled={props.locked}
              testId="main-menu-store"
              authenticated={props.authenticated}
            >
              {i18n.translate('main-menu.store')}
            </StoreMenuItem>
          )}
          {expertBlogEnabled && (
            <MenuItem
              disabled={props.locked}
              icon={icons.Edit}
              testId="main-menu-experts-area-item"
              to="/experts-area"
            >
              {i18n.translate('main-menu.experts-area')}
            </MenuItem>
          )}
          <PromotionsMenuItem
            disabled={props.locked || !props.authenticated}
            testId="main-menu-promotion-notifications"
          >
            {i18n.translate('main-menu.promotion-notifications')}
          </PromotionsMenuItem>
          <MenuItem
            disabled={props.locked || !props.authenticated}
            icon={icons.Face}
            testId="main-menu-account"
            to="/account"
          >
            {i18n.translate('main-menu.account')}
          </MenuItem>
          {authenticated && (
            <SupportChatMenuItem to="/faq" testId="main-menu-support-chat">
              {i18n.translate('main-menu.support-chat')}
            </SupportChatMenuItem>
          )}
          <Common.Space py={0} px={1}>
            <Divider />
          </Common.Space>
          <WalletMenuItem
            animate={props.hasLowBalance}
            disabled={props.locked || !props.authenticated}
            highlightColor="primary"
            icon={icons.BankNote}
            testId="main-menu-wallet-deposit"
            to={`?${depositQuery}`}
          >
            {i18n.translate('main-menu.deposit')}
          </WalletMenuItem>
          <WalletMenuItem
            disabled={props.locked || !props.authenticated}
            highlightColor="secondary"
            icon={icons.CoinStack}
            testId="main-menu-wallet-withdraw"
            to={`?${withdrawalQuery}`}
          >
            {i18n.translate('main-menu.withdraw')}
          </WalletMenuItem>
        </nav>
        <Common.Box
          py={1}
          pb={['50px', null, 1]}
          color="g-text"
          textAlign="center"
          fontSize={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          flexShrink="0"
        >
          {props.authenticated ? (
            <React.Fragment>
              <Common.Box
                opacity="0.46"
                data-testid="main-menu.current-session-time"
              >
                <React.Fragment>
                  <span>
                    {i18n.translate('main-menu.current-session-time')}{' '}
                  </span>
                  <Common.Timestamp>
                    {timestamp =>
                      `${differenceInMinutes(timestamp, props.loggedInAt)} min`
                    }
                  </Common.Timestamp>
                </React.Fragment>
              </Common.Box>
              <ReactRouter.Link to="/logout" data-testid="main-menu-log-out">
                <Common.Space p={0}>
                  {i18n.translate('main-menu.log-out')}
                </Common.Space>
              </ReactRouter.Link>
            </React.Fragment>
          ) : returningPlayer && country.enabled ? (
            <React.Fragment>
              <Common.Box opacity="0.46">
                {i18n.translate('main-menu.not-a-member')}
              </Common.Box>
              {manualSignupEnabled && (
                <ReactRouter.Link
                  to={`?${registerQuery}`}
                  data-testid="main-menu-manual-register"
                >
                  <Common.Space p={0}>
                    {i18n.translate('main-menu.register')}
                  </Common.Space>
                </ReactRouter.Link>
              )}
              {briteSigninEnabled && (
                <ReactRouter.Link
                  to={`?${briteLoginDepositQuery}`}
                  data-testid="main-menu-pay-and-play-register"
                >
                  <Common.Space p={0}>
                    {i18n.translate('main-menu.register')}
                  </Common.Space>
                </ReactRouter.Link>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Common.Box color="black" opacity="1">
                {i18n.translate('main-menu.already-have-an-account')}
              </Common.Box>
              <ReactRouter.Link
                to={`?${loginQuery}`}
                data-testid="main-menu-login"
              >
                <Common.Space p={0}>
                  <Button stretch variant="secondary" fontSize={[1, null, 3]}>
                    {i18n.translate('main-menu.login')}
                  </Button>
                </Common.Space>
              </ReactRouter.Link>
            </React.Fragment>
          )}
        </Common.Box>
      </Menu>
    </Wrapper>
  )
}

MainMenu.propTypes = {
  authenticated: PropTypes.bool,
  hasLowBalance: PropTypes.bool,
  hasNotifications: PropTypes.bool,
  locked: PropTypes.bool,
  loggedInAt: PropTypes.instanceOf(Date),
  storeEnabled: PropTypes.bool,
}
